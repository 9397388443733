@if (showSpinner$ | async) {
  <div class="wrap">
    <div class="ai-loader">
      <div class="img-wrap">
        <img width="210" height="210" ngSrc="/assets/images/cat_schedule.svg" alt="">
      </div>
      <div class="text">{{ 'Генерація результатів' | translate }}</div>
    </div>
  </div>
}
