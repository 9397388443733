import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, mergeMap} from 'rxjs/operators';

import {
  ModalAppointmentComponent
} from '@shared-modules/medications/modals/modal-appointment/modal-appointment.component';
import {
  AppointmentFormService
} from '@shared-modules/medications/services/appointment-form.service';
import {
  RecommendationFormDataInterface
} from '@shared-modules/medications/interfaces/apointment-form.interface';
import {
  ModalMedicationComponent
} from '@shared-modules/medications/modals/modal-medication/modal-medication.component';
import {Consultation} from '@consultations/interfaces/consultation.interface';
import {
  MedicationInterface,
  MedicationListFilterInterface
} from '@shared-modules/medications/interfaces/medication.interface';
import {
  MedicationUserTypesEnum
} from '@shared-modules/medications/enums/medication-user-types.enum';
import {MedicationsService} from '@shared-modules/medications/services/medications.service';
import {
  ModalDirectionsComponent
} from '@shared-modules/medications/modals/modal-directions/modal-directions.component';
import {ConsultationsService} from '@consultations/consultations.service';

@UntilDestroy()

@Component({
  selector: 'app-medications-list',
  templateUrl: './medications-list.component.html',
  styleUrls: ['./medications-list.component.scss']
})
export class MedicationsListComponent implements OnInit, OnChanges {
  @Input() consultation: Consultation;
  @Input() isResultPage = false;
  @Input() appointmentWasUpdated = false;

  hasAppointment = false;
  appointment: RecommendationFormDataInterface;

  medications: MedicationInterface[] = [];

  constructor(
    private dialog: MatDialog,
    private appointmentFormService: AppointmentFormService,
    private medicationsService: MedicationsService,
    private consultationsService: ConsultationsService
  ) {
  }

  ngOnInit(): void {
    this.appointment = this.appointmentFormService.getValueFromCookie(this.consultation.id);
    this.hasAppointment = !!this.appointment.diagnosis.length;
    this.appointmentFormService.hasDirection$.next(this.checkHasDirection(this.consultation));

    this.getMedications();
    this.watchDiagnosisField();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appointmentWasUpdated?.currentValue) {
      this.appointment = this.appointmentFormService.getValueFromCookie(this.consultation.id);
      this.hasAppointment = !!this.appointment.diagnosis.length;
    }
  }

  addAppointment(): void {
    const dialogRef = this.dialog.open(ModalAppointmentComponent, {
      data: {
        consultationId: this.consultation.id,
        isResultPage: this.isResultPage
      },
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(
        filter(r => typeof r === 'boolean'),
        untilDestroyed(this))
      .subscribe(res => {
        this.hasAppointment = res;
        this.appointmentFormService.updateFormData$.next(this.isResultPage);
      });
  }

  addMedications(): void {
    const dialogRef = this.dialog.open(ModalMedicationComponent, {
      data: {
        consultationId: this.consultation.id,
        medications: this.medications
      },
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getMedications());
  }

  addDirections(): void {
    const dialogRef = this.dialog.open(ModalDirectionsComponent, {
      data: {
        consultation: this.consultation
      },
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(
        filter(r => r),
        mergeMap(() => {
          return this.consultationsService.getConsultationById(
            this.consultation.id,
            {with_recommendation: 1}
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(res => {
        this.consultation = res.data;
        this.appointmentFormService.hasDirection$.next(this.checkHasDirection(this.consultation));
      });

  }

  private getMedications(): void {
    const filter: MedicationListFilterInterface = {
      appointmentId: this.consultation.id,
      createdById: this.consultation?.doctor?.id,
      patientId: this.consultation?.patient?.id,
      createdByType: MedicationUserTypesEnum.Doctor,
      status: 1
    };

    this.medicationsService.getMedications(filter)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.medications = res.data.reverse();
        this.appointmentFormService.hasMedication$.next(!!this.medications.length);
      });
  }

  private watchDiagnosisField(): void {
    this.appointmentFormService.updateFormData$
      .pipe(
        filter(r => r),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const appointment = this.appointmentFormService.getValueFromCookie(this.consultation.id);
        this.hasAppointment = !!appointment.diagnosis.length;
      });
  }

  private checkHasDirection(consultation: Consultation): boolean {
    return !!consultation?.analyzes?.length
      || !!consultation?.referralsExamination?.length
      || !!consultation?.referralsSpecialists?.length;
  }
}
