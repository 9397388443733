import {
  AfterViewInit,
  Component, DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

import {AiGenerationService} from '../../core/components/ai-generation/ai-generation.service';
import {CentrifugoSocketChannelsEnum} from '../../core/enums/centrifugo-socket-channels.enum';
import {CentrifugoSocketService} from '../../core/services/centrifugo-socket.service';
import {ModalInfoService} from '../../core/services/modal-info.service';
import {ConsultationsService} from '../../dashboard/consultations/consultations.service';
import {
  Consultation,
  SummaryModel
} from '../../dashboard/consultations/interfaces/consultation.interface';
import {
  CreateConsultationResultFilter,
  GetConsultationByIdFilter
} from '../../dashboard/consultations/interfaces/consultations-filter.interface';
import {ProfileService} from '../../dashboard/profile/profile.service';
import {popStateListener} from '../../helper';
import {
  RecommendationFormDataInterface
} from '../../shared-modules/medications/interfaces/apointment-form.interface';
import {
  AppointmentFormService
} from '../../shared-modules/medications/services/appointment-form.service';
import {
  NotificationTypesEnum
} from '../../shared-modules/notifications/enums/notification-types.enum';
import {ModalInformComponent} from './modal-inform/modal-inform.component';
import {WaitingAreaService} from '../../dashboard/waiting-area/services/waiting-area.service';

@UntilDestroy()
@Component({
  selector: 'app-consultation-result-fill',
  templateUrl: './consultation-result-fill.component.html',
  styleUrls: ['./consultation-result-fill.component.scss']
})
export class ConsultationResultFillComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() consultationFromPreview!: Consultation;
  @Input() offlineConsultationId!: number;
  @Output() closeModal = new EventEmitter();
  @Output() updateConsultation = new EventEmitter();

  params: GetConsultationByIdFilter = {
    relation_anamnes: 1,
    relation_patient: 1,
    with_recommendation: 1,
    doctor_with_review: 1,
    relation_summary: 1
  };

  consultationId: number;
  consultation: Consultation;
  hasMedication: boolean;
  hasDirection: boolean;
  modalInform: any;
  recommendationFormData: RecommendationFormDataInterface;

  appointmentWasUpdated = false;

  private notificationChannel: string;
  private socketClient: any;
  private socket: any;

  constructor(
    private consultationsService: ConsultationsService,
    private route: ActivatedRoute,
    private modalInfoService: ModalInfoService,
    private router: Router,
    private dialog: MatDialog,
    private waitingAreaService: WaitingAreaService,
    private appointmentFormService: AppointmentFormService,
    private aiGenerationService: AiGenerationService,
    private centrifugoSocketService: CentrifugoSocketService,
    private profileService: ProfileService,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnInit(): void {
    this.handleMainSubjects();
    // setTimeout(() => {
    //   this.aiGenerationService.hide();
    // }, 2000);

    if (this.consultationFromPreview) {
      this.consultationId = this.consultationFromPreview.id;
      this.consultation = this.consultationFromPreview;
      this.aiGenerationService.hide();
    } else {
      this.consultationId = this.route.snapshot.params.id || this.offlineConsultationId;
      this.getConsultationResult(this.consultationId);
    }

    this.recommendationFormData = this.appointmentFormService.getValueFromCookie(this.consultationId);
    this.watchRequiredFields();
    this.watchMedicationAndDirection();

    this.enterWaitingArea();
  }

  ngAfterViewInit(): void {
    history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', popStateListener);
  }

  ngOnDestroy() {
    this.unsubscribeFromNotificationChannel();
    this.aiGenerationService.hide();
  }

  onSubmit(): void {
    const recommendation = {
      appointment_id: this.consultation.id,
      idea: this.recommendationFormData.idea,
      diagnosis: this.recommendationFormData.diagnosis,
      recommendations: this.recommendationFormData.recommendation,
      consultation_end: true
    };

    if (!this.offlineConsultationId) {
      delete recommendation.consultation_end;
    }

    this.createRecommendation(recommendation);
  }

  createRecommendation(data: CreateConsultationResultFilter): void {
    if (this.hasDirection || this.hasMedication) {
      this.modalInform = this.dialog.open(ModalInformComponent, {
        data: {
          hasDirection: this.hasDirection,
          hasMedication: this.hasMedication
        },
        panelClass: ['primary-modal', 'modal-sm'],
        autoFocus: false,
        disableClose: true
      });
    }

    this.consultationsService.createConsultationResult(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.appointmentFormService.removeCookie(this.consultationId);

          if (this.modalInform) {
            this.modalInform.close();
          }

          if (this.consultationFromPreview) {
            this.updateConsultation.emit();
            return;
          }

          if (!this.offlineConsultationId) {
            this.router.navigate(['consultation-result/success', this.consultationId]);
            return;
          }

          this.closeModal.emit(true);
        },
        (error) => this.modalInfoService.onError(error)
      );
  }

  private handleMainSubjects(): void {
    combineLatest([
      this.centrifugoSocketService.socketConnectionSuccess$.pipe(filter((r) => r)),
      this.profileService.profile$.pipe(filter((profile) => !!profile))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([socket, profile]) => {
        this.socketClient = socket;
        this.notificationChannel = `${CentrifugoSocketChannelsEnum.NOTIFICATION}${profile.id}`;

        this.subscribeOnNotificationsChannel();
      });
  }

  private subscribeOnNotificationsChannel() {
    this.socket = this.socketClient.getSubscription(this.notificationChannel) || this.socketClient.newSubscription(this.notificationChannel);

    this.socket
      .on('publication', (ctx) => {
        if (ctx.data?.type === NotificationTypesEnum.ConsultationSummary) {
          this.updateRecommendationFromAI(ctx.data, true);
        }
      })
      .subscribe();
  }

  private unsubscribeFromNotificationChannel(): void {
    this.socket?.unsubscribe();
    this.socket?.removeAllListeners();
  }

  private getConsultationResult(id: number): void {
    this.consultationsService
      .getConsultationById(id, this.params)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
          this.consultation = result.data;

          if (this.consultation.appointment_result?.id) {
            this.router.navigate(['consultation-result/success', this.consultationId]);
          }

          if (this.consultation.summary) {
            this.updateRecommendationFromAI(this.consultation.summary);
          }
        },
        () => this.router.navigate(['not-found'])
      );
  }

  private updateRecommendationFromAI(summary: SummaryModel, fromSocket?: boolean) {
    this.aiGenerationService.hide();

    // TODO: Maybe concat the string  if the data from socket
    if (!this.recommendationFormData.idea) {
      this.recommendationFormData.idea = summary.complaints;
    }

    if (!this.recommendationFormData.recommendation) {
      this.recommendationFormData.recommendation = summary.recommendations;
    }

    if (!this.recommendationFormData.diagnosis) {
      this.recommendationFormData.diagnosis = summary.diagnosis;
    }

    this.appointmentFormService.updateCookie(this.recommendationFormData, this.consultationId);

    if (fromSocket) {
      this.appointmentWasUpdated = true;
    }
  }

  private watchRequiredFields(): void {
    this.appointmentFormService.updateFormData$
      .pipe(
        filter(r => r),
        untilDestroyed(this))
      .subscribe(() => {
        this.recommendationFormData = this.appointmentFormService.getValueFromCookie(this.consultationId);
      });
  }

  private watchMedicationAndDirection(): void {
    combineLatest([
      this.appointmentFormService.hasMedication$,
      this.appointmentFormService.hasDirection$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([hasMedication, hasDirection]) => {
        this.hasMedication = hasMedication;
        this.hasDirection = hasDirection;
      });
  }

  private enterWaitingArea(): void {
    this.waitingAreaService.enterWaitingArea()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
